<template>
    <div class="create-release-layout">
        <topWelcomeBanner></topWelcomeBanner>
        <slot></slot>
    </div>
</template>

<script>
import topWelcomeBanner from "@/components/topWelcomeBanner.vue";

export default {
    name: "create-release-layout",
    components: {
        topWelcomeBanner
    }
}
</script>

<style lang="scss">

</style>